export default function ({ route, $chatbot }) {
  const blackList = {
    regexes: [
      /^flashcard/,
      /courses-slug-learn/,
      /courses-slug-study/,
      /sessions-id/,
      /class-id-checkout/,
      /tier-id-checkout/,
      /login/,
      /student-verify/,
      /student-verification/,
      /register/,
      /exams-test/,
      /exams-tests-id/,
      /books/,
      /^exams-app-daily/,
      /^daily-app-section-daily/,
    ],
  };

  const shouldIncludeChatbot = (routeName) => {
    return !blackList.regexes.some((blackRegex) => {
      return blackRegex.test(routeName);
    });
  };

  if (shouldIncludeChatbot(route.name)) {
    $chatbot.setup();
    $chatbot.toggleChatbotDefaultMessage();
  } else {
    $chatbot.remove();
  }
}
