export default {
  props: {
    size: {
      type: [Number, String],
      default: '1em',
    },

    strokeWidth: {
      type: Number,
      default: 0,
    },
  },
};
