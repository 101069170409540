
export default {
  name: 'navbar-logo',

  props: {
    imageClass: {
      type: String,
      default: undefined,
    },
  },
};
