import { defaultState } from './state';
import types from './types';

export default {
  [types.SET_BOOK](state, book) {
    state.book = book;
  },

  [types.SET_BREADCRUMBS](state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  },

  [types.SET_HEADINGS](state, headings) {
    state.headings = headings;
  },

  [types.SET_FONT_FAMILY](state, fontFamily) {
    state.fontFamily = fontFamily;
  },

  [types.SET_FONT_SIZE](state, fontSize) {
    state.fontSize = fontSize;
  },

  [types.SET_LANDING_DATA](state, data) {
    state.landingSettings = data;
  },

  [types.RESET_STATE](state) {
    Object.assign(state, defaultState());
  },

  [types.SET_LIBRARY_THEME](state, libraryTheme) {
    state.libraryTheme = libraryTheme;
  },
};
