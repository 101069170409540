import types from './types';

export default {
  setBook({ commit }, payload) {
    commit(types.SET_BOOK, payload);
  },

  setBreadcrumbs({ commit }, payload) {
    commit(types.SET_BREADCRUMBS, payload);
  },

  setHeadings({ commit }, payload) {
    commit(types.SET_HEADINGS, payload);
  },

  updateFontSetting({ commit }, { fontFamily, fontSize, $cookiz }) {
    $cookiz.set('libraryFontSize', fontSize);
    $cookiz.set('libraryFontFamily', fontFamily);
    commit(types.SET_FONT_FAMILY, fontFamily);
    commit(types.SET_FONT_SIZE, fontSize);
  },

  async fetchLanding({ commit }) {
    const data = await this.$api.library.fetchLanding();
    commit(types.SET_LANDING_DATA, data);
    return data;
  },

  resetState({ commit }) {
    commit(types.RESET_STATE);
  },

  updateLibraryTheme({ commit }, { $cookiz, theme }) {
    $cookiz.set('libraryTheme', theme);
    commit(types.SET_LIBRARY_THEME, theme);
  },
};
