import { sanitizeString } from '~/core/utils/apiUtil';

export default () => ({
  modifyLibraryContent(text) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');

    const headings = doc.querySelectorAll('h1, h2, h3, h4, h5, h6');
    const headingsList = [];

    headings.forEach((heading) => {
      let sanitizedId = sanitizeString(heading.textContent)
        .trim()
        .replace(/^[^a-zA-Z]+/, '_') // Ensure it starts with a letter
        .toLowerCase()
        .split(' ')
        .join('-');

      heading.id = sanitizedId;
      headingsList.push({ id: heading.id, title: heading.textContent });
    });

    return {
      modifiedContent: doc.body.innerHTML,
      headings: headingsList,
    };
  },
});
