
export default {
  name: 'logo',

  props: {
    imageClass: {
      type: String,
      default: 'h-10 w-10',
    },
  },
};
