export default {
  book: ({ book }) => book,
  breadcrumbs: ({ breadcrumbs }) => breadcrumbs,
  breadcrumbsLabels: (_, { breadcrumbs, book }) => [book?.title, ...breadcrumbs.map(({ title }) => title)],
  breadcrumbsSlugs: (_, { breadcrumbs }) => breadcrumbs.map(({ slug }) => slug),
  headings: ({ headings }) => headings,
  fontFamily: ({ fontFamily }) => fontFamily,
  fontSize: ({ fontSize }) => fontSize,
  landingSettings: ({ landingSettings }) => landingSettings,
  libraryTheme: ({ libraryTheme }) => libraryTheme,
};
