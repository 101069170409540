export const defaultState = () => ({
  book: {},
  breadcrumbs: [],
  headings: [],
  fontFamily: 'inter',
  fontSize: 'default',
  landingSettings: {},
  libraryTheme: 'light',
});

export default () => defaultState();
