import { render, staticRenderFns } from "./login-form.vue?vue&type=template&id=36354c4a&scoped=true"
import script from "./login-form.vue?vue&type=script&lang=js"
export * from "./login-form.vue?vue&type=script&lang=js"
import style0 from "./login-form.vue?vue&type=style&index=0&id=36354c4a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36354c4a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/components/ui/icon/icon.vue').default,LoginVerifyCode: require('/app/components/login/login-verify-code.vue').default,LoginSignin: require('/app/components/login/login-signin.vue').default,LoginSignup: require('/app/components/login/login-signup.vue').default})
