import { render, staticRenderFns } from "./JWDateInput.vue?vue&type=template&id=4e8975ac"
import script from "./JWDateInput.vue?vue&type=script&lang=js"
export * from "./JWDateInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/components/ui/icon/icon.vue').default,FormError: require('/app/components/ui/form/form-error.vue').default})
