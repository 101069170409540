
import CookieBannerWrapper from '@/components/ui/banner/accept-cookie-banner-wrapper.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    CookieBannerWrapper,
  },

  data() {
    return {
      isBooksRoute: false,
    };
  },

  computed: {
    ...mapGetters('library', ['libraryTheme']),
    shouldBannerBeHide() {
      const blackListRoutes = ['student-verify'];
      return blackListRoutes.includes(this.$route.name);
    },
    isDarkMode() {
      return this.libraryTheme === 'dark';
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.isBooksRoute = this.$route.path.startsWith('/books/') ?? false;

        this.checkTheme();
      },
    },
  },

  beforeMount() {
    this.checkTheme();
  },

  methods: {
    ...mapActions('library', ['updateLibraryTheme']),
    checkTheme() {
      const savedTheme = this.$cookiz.get('libraryTheme') || 'light';
      if (this.libraryTheme !== savedTheme) {
        this.updateLibraryTheme({
          theme: savedTheme,
          $cookiz: this.$cookiz,
        });
      }
    },
  },
};
