export default {
  SET_BOOK: 'SET_BOOK',
  SET_BREADCRUMBS: 'SET_BREADCRUMBS',
  SET_HEADINGS: 'SET_HEADINGS',
  SET_FONT_FAMILY: 'SET_FONT_FAMILY',
  SET_FONT_SIZE: 'SET_FONT_SIZE',
  SET_LANDING_DATA: 'SET_LANDING_DATA',
  RESET_STATE: 'RESET_STATE',
  SET_LIBRARY_THEME: 'SET_LIBRARY_THEME',
  SET_CUSTOM_BLOCKS_ACTIVATED: 'SET_CUSTOM_BLOCKS_ACTIVATED',
};
